<template>
    <b-card-code
        title="Add New Service Package"
    >
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            label="Package Title"
                            label-for="package-name">
                            <validation-provider
                                #default="{ errors }"
                                name="Package Title"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="servicePackageInfo.title"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Package Title"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Package SubTitle"
                            label-for="subtitle">
                            <validation-provider
                                #default="{ errors }"
                                name="Package SubTitle"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="servicePackageInfo.subtitle"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Package SubTitle"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="PPP Limit"
                            label-for="ppp_limit">
                            <validation-provider
                                #default="{ errors }"
                                name="ppp_limit"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="servicePackageInfo.ppp_limit"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter PPP Limit"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Router Limit"
                            label-for="route_limit">
                            <validation-provider
                                #default="{ errors }"
                                name="route_limit"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="servicePackageInfo.route_limit"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Router Limit"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Price"
                            label-for="price">
                            <validation-provider
                                #default="{ errors }"
                                name="Price"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="servicePackageInfo.price"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Package Price"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Currency"
                            label-for="currency">
                            <validation-provider
                                #default="{ errors }"
                                name="currency"
                                rules="required"
                            >
                                <b-form-input
                                    v-model="servicePackageInfo.currency"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Enter Currency"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Status"
                            label-for="status">
                            <validation-provider
                                #default="{ errors }"
                                name="Status"
                                rules="required"
                            >
                                <b-form-select
                                    v-model="servicePackageInfo.status"
                                    :options="status"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-button
                            type="submit"
                            variant="primary"
                            @click.prevent="validationForm"
                        >
                            Submit
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card-code>
</template>

<script>

import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
    BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText, BFormSelect,
} from 'bootstrap-vue'
import {required} from '@validations'
import useJwt from "@/auth/jwt/useJwt";
import Mixin from "@core/services/mixin";
import {checkRes} from "@core/services/helper";
import {ADMIN_SERVICES_PACKAGE_DETAIL, PACKAGE_VIEW} from "@core/services/api";
import router from "@/router";

export default {
    mixins: [
        Mixin
    ],
    components: {
        BFormSelect,
        BCardCode,
        ValidationProvider,
        ValidationObserver,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
    },
    data() {
        return {
            servicePackageId:router.currentRoute.params.servicePid,
            servicePackageInfo: 'null',
            title: null,
            subtitle: null,
            ppp_limit: null,
            route_limit: null,
            currency: null,
            price: null,
            selected: null,
            status: [
                {value: null, text: 'Please select status'},
                {value: '1', text: 'Active'},
                {value: '0', text: 'Inactive'}
            ],
            required,
        }
    },
    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    useJwt.createServicePackageAdmin({
                        id:this.servicePackageId,
                        title: this.servicePackageInfo.title,
                        subtitle: this.servicePackageInfo.subtitle,
                        ppp_limit: this.servicePackageInfo.ppp_limit,
                        route_limit: this.servicePackageInfo.route_limit,
                        price: this.servicePackageInfo.price,
                        currency: this.servicePackageInfo.currency,
                        status: this.servicePackageInfo.status,
                    }).then(response => {
                        if (checkRes(response.data.code)){
                            console.log(response.data.code);
                            this.$router.push({name: 'admin-isp-service-package-list'})
                                .catch(error => {
                                    this.toastMessage('success','Updated Service Package', response)
                                })
                        } else {
                            this.$router.push({name: 'admin-isp-service-package-list'})
                                .catch(error => {
                                    this.toastMessage('warning','Service Package', response)
                                })
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }
            })
        },
        getServicePackageDetail() {
            this.$http.get(process.env.VUE_APP_BASEURL+  ADMIN_SERVICES_PACKAGE_DETAIL + this.servicePackageId)
                .then(res => {
                    this.servicePackageInfo = res.data.data.service_package_detail
                }).catch(error => {
                console.log(error)
            })
        },
    },
    mounted() {
        this.getServicePackageDetail()
    }
}
</script>
