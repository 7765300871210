var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Add New Service Package"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Package Title","label-for":"package-name"}},[_c('validation-provider',{attrs:{"name":"Package Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Package Title"},model:{value:(_vm.servicePackageInfo.title),callback:function ($$v) {_vm.$set(_vm.servicePackageInfo, "title", $$v)},expression:"servicePackageInfo.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Package SubTitle","label-for":"subtitle"}},[_c('validation-provider',{attrs:{"name":"Package SubTitle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Package SubTitle"},model:{value:(_vm.servicePackageInfo.subtitle),callback:function ($$v) {_vm.$set(_vm.servicePackageInfo, "subtitle", $$v)},expression:"servicePackageInfo.subtitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"PPP Limit","label-for":"ppp_limit"}},[_c('validation-provider',{attrs:{"name":"ppp_limit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter PPP Limit"},model:{value:(_vm.servicePackageInfo.ppp_limit),callback:function ($$v) {_vm.$set(_vm.servicePackageInfo, "ppp_limit", $$v)},expression:"servicePackageInfo.ppp_limit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Router Limit","label-for":"route_limit"}},[_c('validation-provider',{attrs:{"name":"route_limit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Router Limit"},model:{value:(_vm.servicePackageInfo.route_limit),callback:function ($$v) {_vm.$set(_vm.servicePackageInfo, "route_limit", $$v)},expression:"servicePackageInfo.route_limit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Price","label-for":"price"}},[_c('validation-provider',{attrs:{"name":"Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Package Price"},model:{value:(_vm.servicePackageInfo.price),callback:function ($$v) {_vm.$set(_vm.servicePackageInfo, "price", $$v)},expression:"servicePackageInfo.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Currency","label-for":"currency"}},[_c('validation-provider',{attrs:{"name":"currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Currency"},model:{value:(_vm.servicePackageInfo.currency),callback:function ($$v) {_vm.$set(_vm.servicePackageInfo, "currency", $$v)},expression:"servicePackageInfo.currency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"status"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.status},model:{value:(_vm.servicePackageInfo.status),callback:function ($$v) {_vm.$set(_vm.servicePackageInfo, "status", $$v)},expression:"servicePackageInfo.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }